import { Organization } from '../new-mentorship/types'
import { CreateOrUpdateOrganizationParam } from '../volunteering/organizations/models'

export function mapOrgToPayload(organization: Organization): CreateOrUpdateOrganizationParam {
  const { address: existingAddress, location, ...rest } = organization

  let address: any
  if (location && location.coordinates && existingAddress) {
    const [lng, lat] = location.coordinates
    address = { ...existingAddress, gps: { lat, lng } }
  }
  return {
    ...rest,
    address,
  }
}
