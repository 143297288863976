import { useCallback, useEffect, useState } from 'react'
import { useRemoteCollection } from 'civic-champs-shared/api/hooks'
import {
  useAddEditRolePrompt,
  useFetchRoles,
  useMergeRolesPrompt,
  useRemoveRolePrompt,
  useReorderRolesPrompt,
} from './index'

export const useRolesCollection = ({ reporting = true, forLocations } = {}) => {
  const { fetchRoles, loading, error } = useFetchRoles()
  const [roles, operations, events] = useRemoteCollection()
  const [initiallyLoaded, setInitiallyLoaded] = useState(false)

  const { syncCollection, eagerAdd, eagerReplace, eagerRemove } = operations
  const getRoles = useCallback(async () => {
    const updatedRoles = await fetchRoles({ reporting, forLocations })

    syncCollection(updatedRoles)
    setInitiallyLoaded(true)
  }, [fetchRoles, forLocations, reporting, syncCollection])

  useEffect(() => {
    getRoles()
  }, [getRoles])

  const addRole = useAddEditRolePrompt({ eagerAdd })
  const editRole = useAddEditRolePrompt({ eagerReplace })
  const deleteRole = useRemoveRolePrompt(eagerRemove)
  const reorderRoles = useReorderRolesPrompt(getRoles)
  const mergeRoles = useMergeRolesPrompt(getRoles)

  return [
    {
      roles,
      loading,
      error,
      initiallyLoaded,
    },
    {
      addRole: options => addRole(null, options),
      editRole,
      deleteRole,
      reorderRoles,
      mergeRoles,
    },
    events,
  ]
}

export default useRolesCollection
