import { makeStyles } from '@material-ui/core/styles'

export const TOP_PADDING = 10

export default makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: `${TOP_PADDING}px 0 20px`,
    height: '100%',
    overflow: 'auto',
    position: 'relative',
  },
  expansionRoot: {
    boxShadow: 'none',
  },
  expandIcon: {
    color: '#000000',
    marginRight: 0,
    padding: '0 19px',
  },
  summaryRoot: {
    flexDirection: 'row-reverse',
    background: '#D6E3FF',
    height: '52px',
    minHeight: '52px',
    padding: 0,
    marginBottom: '10px',
    zIndex: 1,
    '&$expanded': {
      height: '52px',
      minHeight: '52px',
      margin: 0,
    },
  },
  detailsRoot: {
    padding: '10px 62px',
    margin: 0,
    flexDirection: 'column',
  },
  details: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  summaryContent: {
    alignItems: 'center',
    gap: '10px',
    '& h1': {
      color: '#000',
      fontSize: '24px',
      fontFamily: 'Poppins',
      fontWeight: 500,
      lineHeight: '32px',
      margin: 0,
    },
  },
  expanded: {},
  content: {
    display: 'flex',
    padding: '20px 62px',
  },
  heading: {},
  editIcon: {
    cursor: 'pointer',
    color: '#0F5DB5',
    width: '20px',
  },
  eventInfo: {
    width: '332px',
    display: 'flex',
    padding: '0px 2px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '20px',
  },
  sectionHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    '&:not(:first-child)': {
      borderTop: '1px solid rgba(0, 0, 0, 0.10)',
      paddingTop: '12px',
    },
  },
  sectionTitle: {
    fontSize: '16px',
    fontFamily: 'Nunito',
    fontWeight: 700,
    lineHeight: '24px',
    letterSpacing: '0.1px',
    margin: 0,
  },
  sectionRow: {
    display: 'flex',
    fontSize: '14px',
    fontFamily: 'Nunito',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0.1px',
    '& > div': {
      flexShrink: 0,
    },
    '& > div:nth-child(1)': {
      fontWeight: 700,
      width: '132px',
    },
    '& > div:nth-child(2)': {
      width: '200px',
    },
  },
  description: {
    maxWidth: '575px',
    minWidth: '235px',
    flexShrink: 0,
    flexGrow: 0,
    display: 'flex',
    flexDirection: 'column',
    fontSize: '14px',
    fontFamily: 'Nunito',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0.25px',
    '& > div': {
      '& p, h1, h2, h3, ul, a': {
        margin: 0,
      },
      '& a': {
        color: '#0F5DB5',
        textDecoration: 'none',
        cursor: 'pointer',
      },
    },
    '& > h3': {
      margin: 0,
      marginBottom: '5px',
      padding: 0,
      fontWeight: 700,
      letterSpacing: '0.1px',
    },
    '& > h3:not(nth-first-child)': {
      marginTop: '20px',
    },
  },
  support: {
    '@media (max-width: 1100px)': {
      display: 'none',
    },
  },
  addButton: {
    marginRight: '-64px',
  },
  buttons: {
    marginBottom: '10px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  signupSummary: {
    width: '300px',
  },
  rightButtons: {
    display: 'flex',
    gap: '20px',
  },
  popoverButton: {
    padding: '4px',
    border: '1px solid #0F5DB5',
    borderRadius: '5px',
    background: 'white',
    width: '20px',
    height: '20px',
    color: '#0F5DB5',
    '& > span > svg': {
      fontSize: '20px',
    },
  },
  popover: {
    maxWidth: '180px',
    padding: '10px',
    gap: '10px',
  },
}))
