import { makeStyles } from '@material-ui/styles'

export const FULL_HEIGHT = 336
export const HALF_HEIGHT = 168
export const ROW_HEIGHT = 28

export const useVolunteerSelectorStyles = makeStyles<any, { noMargin?: boolean; alternative?: boolean }>(
  (theme: any) => ({
    input: {
      padding: '15px !important',
      fontFamily: 'Nunito, sans-serif',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '0.5px',
      color: '#191C1D',
      cursor: 'pointer',
      background: '#FFFFFF',
      border: `1px solid ${theme.palette.secondary.main}`,
      borderRadius: '4px',
      display: 'flex',
      justifyContent: 'space-between',
      '&$open': {
        borderRadius: '4px 4px 0px 0px',
      },
      margin: ({ noMargin }: { noMargin?: boolean }) => (noMargin ? 0 : '10px 0'),
      position: 'relative',
    },
    open: {},
    expandCollapse: {
      marginRight: ({ alternative }: { alternative?: boolean }) => (alternative ? '-8px' : '2px'),
    },
    text: {
      display: 'flex',
      '& > span': {
        color: theme.palette.primary.main,
      },
    },
    whiteBg: {
      background: '#fff',
    },
    popper: {
      zIndex: 1301,
      width: '337px',
      // For some reason positioning is 1px
      marginLeft: '1px',
    },
    popperPaper: {
      width: '337px',
      background: 'white',
      display: 'flex',
      flexDirection: 'column',
      border: `1px solid ${theme.palette.secondary.main}`,
      borderRadius: '0px 0px 4px 4px',
    },
    recipients: {
      display: 'flex',
      flexDirection: 'column',
      maxHeight: `${HALF_HEIGHT}px`,
      overflow: 'auto',
    },
    placeholder: {
      flexShrink: 0,
      width: '100%',
    },
    selected: {
      padding: '8px 15px 8px 20px',
      fontFamily: 'Nunito',
      fontWeight: 700,
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: '0.4px',
      color: '#000',
      background: 'white',
    },
    label: {
      position: 'absolute',
      top: '-6px',
      left: '8px',
      fontSize: '12px',
      background: 'white',
      lineHeight: '12px',
      padding: '0px 4px',
    },
    textPlaceholder: {
      color: '#74777F',
    },
  }),
)

export const useVolunteerRowStyles = makeStyles((theme: any) => ({
  recipientRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 15px 0 20px',
    height: `${ROW_HEIGHT}px`,
    fontFamily: 'Nunito',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.4px',
    color: '#191C1D',
    flexShrink: 0,
    background: 'white',
    width: '100%',
  },
  rowIcon: {
    fontSize: '20px',
    color: theme.palette.primary.main,
  },
  closeIcon: {
    fontSize: '20px',
    color: '#000',
    cursor: 'pointer',
    opacity: 0,
    transition: 'opacity 0.2s',
    '&:hover': {
      opacity: 1,
    },
  },
  recipientContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '10px',
    width: '100%',
  },
  recipientNameContainer: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    padding: 0,
    margin: 0,
    maxWidth: '240px',
    flexShrink: 0,
    display: 'flex',
    gap: '2px',
    width: '100%',
    justifyContent: 'space-between',
  },
  email: {
    width: '100%',
  },
}))
