import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'
import { useCallback } from 'react'
import { currentOrgUpdated } from 'auth/actions'
import { useCurrentOrg } from 'auth/hooks'
import useCreateOrUpdateOrganization from 'volunteering/organizations/hooks/useCreateOrUpdateOrganization'
import { useDispatch } from 'react-redux'
import { useCreateApiNotification } from 'civic-champs-shared/api/hooks'
import OrganizationSettingsModal from 'organization/component/OrganizationSettingsModal'
import { mapOrgToPayload } from '../helpers'

export const useShowOrganizationSettingsEdit = () => {
  const showEditOrgSettings = useShowPrompt(OrganizationSettingsModal)
  const organization = useCurrentOrg()
  const createOrUpdateOrganization = useCreateOrUpdateOrganization()
  const dispatch = useDispatch()
  const createNotification = useCreateApiNotification()
  return useCallback(async () => {
    const payload = await showEditOrgSettings()
    const gps = payload.address?.geofencing?.location?.coordinates
    if (gps) {
      payload.address.gps = gps
    } else if (organization.location && organization.location.coordinates) {
      const [lng, lat] = organization.location?.coordinates
      payload.address.gps = { lat, lng }
    }
    const notification = createNotification(`Saving Organization`)
    try {
      const updatedOrg = await createOrUpdateOrganization(
        { ...mapOrgToPayload(organization), ...payload },
        organization.id,
      )
      dispatch(currentOrgUpdated(updatedOrg))
      notification.onSuccess('Organization saved successfully')
    } catch (e) {
      notification.onError('Failed to save organization', e)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization])
}
