import { OpportunityResponse } from 'Event/interfaces/interfaceCreateEditEvent'
import { OpportunityTemplate } from 'volunteering/opportunities/interfaces'
import { mapGeofencingToEventGeofencing } from 'utils/event'
import { OpportunityVisibility } from 'Event/interfaces'

export const mapEventToTemplate = (opportunity: OpportunityResponse): Omit<OpportunityTemplate, 'id'> => ({
  name: opportunity.name,
  isActive: true,
  streetAddress: opportunity.address || null,
  geofencing: mapGeofencingToEventGeofencing(opportunity.geofencing),
  city: opportunity.city || null,
  state: opportunity.state || null,
  zip: opportunity.zip || null,
  isPrivate: opportunity.visibility === OpportunityVisibility.PRIVATE,
  isRecurring: opportunity.is_recurring || false,
  recurrencePattern: opportunity.recurrenceInfo?.pattern || null,
  visibility: opportunity.visibility,
  locationDetails: opportunity.locationDetails || null,
  contactName: opportunity.contact_name || null,
  contactEmail: opportunity.contact_email || null,
  contactPhoneNumber: opportunity.contact_phone_number || null,
  description: opportunity.description || null,
  instructions: opportunity.instructions || null,
  isSchedulable: opportunity.schedulable,
  organizationLocationId: opportunity.locationId || null,
  organizationId: opportunity.organization.id,
  locationIsAddress: opportunity.locationIsAddress,
  usageCount: 0,
  createdById: 0,
  updatedById: null,
  deletedById: null,
  createdAt: new Date(),
  updatedAt: null,
  deletedAt: null,
  order: 0,
})
