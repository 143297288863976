import { OpportunityTemplate, OrderBody } from 'volunteering/opportunities/interfaces'
import { useCreateApiNotification, useRemoteCollection } from 'civic-champs-shared/api/hooks'
import useGetOpportunityTemplates from 'volunteering/opportunities/hooks/useGetOpportunityTemplates'
import { useCallback, useEffect } from 'react'
import useDeleteOpportunityTemplate from 'volunteering/opportunities/hooks/useDeleteOpportunityTemplate'
import useReorderOpportunityTemplatesPrompt from 'volunteering/opportunities/hooks/useReorderOpportunityTemplatesPrompt'
import { CollectionEventListeners } from 'civic-champs-shared/api/hooks/useRemoteCollection'
import { useShowTemplateAddEditPrompt } from 'volunteering/opportunities/hooks/useShowTemplateAddEditPrompt'

interface OpportunityTemplatesCollectionData {
  opportunityTemplates: OpportunityTemplate[]
  loading: boolean
  refresh: () => void
}
interface OpportunityTemplateCollectionOperations {
  remove: (body: OpportunityTemplate) => void
  showAddEditDialog: (template?: OpportunityTemplate) => any
  reorder: (body: OrderBody[]) => void
}

export default function useOpportunityTemplateCollection(
  isSchedulable: boolean,
): [
  OpportunityTemplatesCollectionData,
  OpportunityTemplateCollectionOperations,
  CollectionEventListeners<OpportunityTemplate>,
] {
  const [opportunityTemplates, operations, events] = useRemoteCollection<OpportunityTemplate>()
  const createNotification = useCreateApiNotification()

  const [fetchOpportunityTemplates, { loading }] = useGetOpportunityTemplates(isSchedulable)
  const [deleteOpportunityTemplate] = useDeleteOpportunityTemplate()

  const refresh = useCallback(() => {
    fetchOpportunityTemplates().then(operations.syncCollection)
  }, [fetchOpportunityTemplates, operations.syncCollection])

  useEffect(() => {
    refresh()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const reorder = useReorderOpportunityTemplatesPrompt(isSchedulable, refresh)

  const remove = useCallback(
    async (opportunityTemplate: OpportunityTemplate) => {
      const notification = createNotification('Deleting template')
      try {
        await deleteOpportunityTemplate(opportunityTemplate)
        operations.eagerRemove(opportunityTemplate)
        notification.onSuccess('Template deleted')
      } catch (e) {
        notification.onError('Failed to delete template', e)
      }
    },
    [createNotification, deleteOpportunityTemplate, operations],
  )

  const showAddEditDialog = useShowTemplateAddEditPrompt({
    onCreate: operations.eagerAdd,
    onUpdate: operations.eagerReplace,
  })

  return [{ opportunityTemplates, loading, refresh }, { reorder, remove, showAddEditDialog }, events]
}
