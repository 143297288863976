import { get, map } from 'lodash'
import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { makeStyles } from '@material-ui/core/styles'

import { filterByRequireRules } from 'civic-champs-shared/utils/filterByRequireRules'

import { contactSort, ExpandableCell, NameCell } from 'core/table/cells/modern'
import { EditCell, EditHeader, FixedPointCell, PreFormattedCell } from 'core/table/cells'
import { DYNAMIC_NUMBER, DYNAMIC_TEXT } from 'core/table/filters'
import { createStringSort, createTagsSort } from 'core/table/utils'
import { createDateObjectSort } from 'components/table/sort'

import format from 'civic-champs-shared/utils/format'
import activitySources from '../../enum/activitySources'
import { useGroups } from 'group/hooks/useGroups'
import { LOG_OTHER_HOURS } from '../../enum/entryMethod'
import { useUISettings } from 'hooks/useUISettings'
import { getSmileyOptions, SatisfactionCell, SatisfactionHeader } from '../components'
import { useOrganizationRoles } from './useOrganizationRoles'
import { FIRST_LAST_NAME, GROUPS, REACTIONS, ROLES } from '../utils/filters'
import { useTimeZone } from '../../../civic-champs-shared/auth/hooks'
import { TAGS } from '../../../champion/utils/filters'
import { CellProps } from 'react-table'
import Tags from '../../../components/tag/Tags'
import { Tag } from '../../../new-mentorship/types'
import { useFeatureEnabled } from '../../../core/feature/hooks'
import { useTagsCollection } from '../../../tags/hooks'

export const useStyles = makeStyles({
  link: {
    color: '#0F5DB5',
    textDecoration: 'none',
    cursor: 'pointer',
  },
  selectColumn: {
    '&>div': {
      width: '46px',
    },
  },
  idColumn: {
    '&>div': {
      width: '70px',
    },
  },
  nameColumn: {
    '&>div': {
      width: '160px',
    },
  },
  firstNameColumn: {
    '&>div': {
      width: '90px',
    },
  },
  lastNameColumn: {
    '&>div': {
      width: '110px',
    },
  },
  opportunityNameColumn: {
    '&>div': {
      width: '160px',
    },
  },
  roleNameColumn: {
    '&>div': {
      width: '160px',
    },
  },
  checkInColumn: {
    '&>div': {
      width: '160px',
    },
  },
  satisfactionColumn: {
    '&>div': {
      width: '80px',
    },
  },
  editColumn: {
    '&>div': {
      width: '40px',
    },
  },
  hoursColumn: {
    '&>div': {
      width: '140px',
    },
  },
  groupsColumn: {
    '&>div': {
      width: '310px',
    },
  },
})

const getEntryMethod = (activity: any) => {
  // @ts-ignore
  let source = activitySources[activity.occurredAtSource]
  return get(source, 'entryMethod', LOG_OTHER_HOURS)
}

const getCheckInCellValue = (timeZone: string) => (activity: any) => {
  let fromLogOtherHours = getEntryMethod(activity) === LOG_OTHER_HOURS
  let occurredAt = moment.tz(activity.occurredAt, timeZone)
  if (fromLogOtherHours) {
    occurredAt.startOf('day')
  }

  let display = fromLogOtherHours ? format.date(occurredAt) : format.datetime(occurredAt, false)

  return { value: occurredAt, display: display, width: '10em' }
}

export const useActivityLogColumns = (
  actions: {
    onEdit: (activity: { id: number }) => void
  },
  opportunityId?: number,
) => {
  const classes = useStyles()
  // @ts-ignore
  const { showAdminItems } = useUISettings()
  const { roles } = useOrganizationRoles()
  const { groups } = useGroups(undefined, false)
  const timeZone = useTimeZone()
  const isVolunteerTagsEnabled = useFeatureEnabled('ActivityVolunteerTags')
  const [{ tags }] = useTagsCollection()

  return useMemo(
    () =>
      filterByRequireRules(
        [
          {
            id: 'id',
            Header: 'Id',
            accessor: 'id',
            disableFilters: true,
            requires: 'showAdminItems',
          },

          {
            id: 'name',
            Header: 'Name',
            accessor: 'user',
            Cell: ({ value: { id, givenName, familyName } }: any) => (
              <Link className={classes.link} to={`/champion/${id}`}>
                {givenName} {familyName}
              </Link>
            ),
            filter: FIRST_LAST_NAME,
            className: classes.nameColumn,
            sortType: contactSort,
          },
          {
            id: 'firstName',
            Header: 'First Name',
            accessor: 'user.givenName',
            Cell: NameCell,
            filter: DYNAMIC_TEXT,
            className: classes.firstNameColumn,
            sortType: createStringSort('firstName'),
          },
          {
            id: 'lastName',
            Header: 'Last Name',
            accessor: 'user.familyName',
            Cell: NameCell,
            filter: DYNAMIC_TEXT,
            className: classes.lastNameColumn,
            sortType: createStringSort('lastName'),
          },
          {
            id: 'opportunity',
            Header: 'Opportunity',
            accessor: 'opportunity.name',
            className: classes.opportunityNameColumn,
            filter: DYNAMIC_TEXT,
            requires: 'noOpportunityId',
          },
          {
            id: 'role',
            Header: 'Role',
            accessor: 'volunteeringRole.name',
            className: classes.roleNameColumn,
            filter: ROLES,
            getFilterAutocomplete: (): string[] => map(roles, 'name').sort(),
          },
          {
            id: 'checkIn',
            Header: 'Check-In',
            accessor: getCheckInCellValue(timeZone),
            Cell: PreFormattedCell,
            sortType: createDateObjectSort('checkIn'),
            className: classes.checkInColumn,
            disableFilters: true,
          },
          {
            id: 'reflections',
            Header: 'Reflections',
            accessor: 'reflection',
            Cell: ExpandableCell,
            className: classes.groupsColumn,
          },
          {
            id: 'hours',
            Header: 'Hours',
            accessor: 'hoursVolunteered',
            Cell: FixedPointCell,
            filter: DYNAMIC_NUMBER,
            sortType: 'basic',
          },
          {
            id: 'satisfaction',
            Header: <SatisfactionHeader />,
            Title: 'Reactions',
            accessor: 'volunteerSatisfaction',
            Cell: SatisfactionCell,
            disableSortBy: true,
            filter: REACTIONS,
            getFilterAutocomplete: getSmileyOptions,
            className: classes.satisfactionColumn,
          },
          {
            id: 'actions',
            Header: <EditHeader hideDelete />,
            Cell: ({ cell }: any) => <EditCell cell={cell} onEdit={actions.onEdit} />,
            disableSortBy: true,
            disableFilters: true,
            className: classes.editColumn,
          },
          {
            id: 'groups',
            Header: 'Groups',
            accessor: 'groups',
            Cell: ExpandableCell,
            filter: GROUPS,
            className: classes.groupsColumn,
            sortType: createStringSort('groups'),
            getFilterAutocomplete: (): any[] => map(groups, 'name'),
          },
          {
            Header: 'Volunteer Tags',
            accessor: 'volunteerTags',
            id: 'volunteerTags',
            filter: TAGS,
            requires: 'isVolunteerTagsEnabled',
            sortType: createTagsSort('volunteerTags'),
            Cell: ({ cell: { value } }: CellProps<any>) => (
              <Tags tags={(value || []).map(({ name: label, id, color }: Tag) => ({ label, id, color }))} />
            ),
            getFilterAutocomplete: () => tags.map(({ id, name, color }) => ({ label: name, value: id, color })),
            isTags: true,
          },
        ],
        {
          showAdminItems,
          noOpportunityId: !opportunityId,
          isVolunteerTagsEnabled,
        },
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [actions.onEdit, roles.length, showAdminItems, isVolunteerTagsEnabled, tags, groups],
  )
}

export default useActivityLogColumns
