import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'
import OrganizationDataModal from 'organization/component/OrganizationDataModal'
import { useCallback } from 'react'
import { currentOrgUpdated } from 'auth/actions'
import { useCurrentOrg } from 'auth/hooks'
import useCreateOrUpdateOrganization from 'volunteering/organizations/hooks/useCreateOrUpdateOrganization'
import { useDispatch } from 'react-redux'
import { useCreateApiNotification } from 'civic-champs-shared/api/hooks'
import { mapOrgToPayload } from '../helpers'

export const useShowOrganizationEdit = () => {
  const showEditOrgData = useShowPrompt(OrganizationDataModal)
  const organization = useCurrentOrg()
  const createOrUpdateOrganization = useCreateOrUpdateOrganization()
  const dispatch = useDispatch()
  const createNotification = useCreateApiNotification()
  return useCallback(async () => {
    const { name, website, timeZone } = await showEditOrgData()
    const notification = createNotification(`Saving Organization`)
    try {
      const updatedOrg = await createOrUpdateOrganization(
        { ...mapOrgToPayload(organization), name, website, timeZone: timeZone.value },
        organization.id,
      )
      dispatch(currentOrgUpdated(updatedOrg))
      notification.onSuccess('Organization saved successfully')
    } catch (e) {
      notification.onError('Failed to save organization', e)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization])
}
