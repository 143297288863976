import React, { useCallback, useEffect, useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DraggablePaper from 'civic-champs-shared/core/confirm-dialog/DraggablePaper'
import DialogTitle from '@material-ui/core/DialogTitle'
import { IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import OutlinedButton from 'civic-champs-shared/core/OutlinedButton'
import ContainedButton from 'civic-champs-shared/core/ContainedButton'
import { makeStyles, ThemeProvider } from '@material-ui/core/styles'
import { muiTheme } from 'theme'
import { Person } from 'people/interface'
import cn from 'classnames'
import { PersonSuggestion } from 'civic-champs-shared/api/hooks/useFetchPersonSuggestions'

import { TagAutocomplete } from 'tags/components/TagAutoComplete'
import { Field, Formik } from 'formik'
import { Tag } from '../../new-mentorship/types'
import useTagManagement from '../../new-mentorship/hooks/useTagsManagement'
import map from 'lodash/map'
import { PersonRef } from '../../civic-champs-shared/common/types'
import VolunteerSelector from '../../tracking/activity/components/VolunteerSelector'
import useAddRemoveTagsPromptStyles from '../hooks/useAddRemoveTagsPromptStyles'

interface AddRemoveTagsPromptProps {
  showing: boolean
  complete: (value: any) => void
  close: () => void
  persons: Person[]
  hidePersonSelector?: boolean
}

const useStyles = makeStyles({
  dialog: { width: '533px' },
  title: { marginBottom: 0 },
  gap: { marginBottom: '16px' },
})

interface FormProps {
  persons: (Person | PersonSuggestion)[]
  add: Tag[]
  remove: Tag[]
}

export const AddRemoveTagsPrompt = (props: AddRemoveTagsPromptProps) => {
  const { showing, close, complete, persons } = props
  const { getOrgTags } = useTagManagement()
  const [tags, setTags] = useState<Tag[]>([])
  useEffect(() => {
    getOrgTags().then(value => {
      setTags(value)
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  const classes = useAddRemoveTagsPromptStyles({
    hidePersonSelector: props.hidePersonSelector,
  })
  const styles = useStyles()
  const handleComplete = useCallback(
    ({ persons, add, remove }: FormProps) => {
      complete({
        removeTagIds: map(remove, 'id'),
        addTagIds: map(add, 'id'),
        personIds: map(persons, 'id'),
      })
    },
    [complete],
  )

  return (
    // @ts-ignore
    <Dialog
      open={showing}
      // @ts-ignore
      PaperComponent={DraggablePaper}
      // @ts-ignore
      PaperProps={{ handle: `#add-remove-tags-prompt` }}
      aria-labelledby="add-remove-tags"
      maxWidth="xs"
      classes={{ paper: cn(classes.dialog, styles.dialog) }}
      disableEnforceFocus
    >
      <DialogTitle
        classes={{ root: cn(classes.title, styles.title) }}
        disableTypography={true}
        id="add-remove-tags-prompt"
      >
        Add/Remove Tags
        <IconButton className={classes.dialogCloseButton} onClick={() => close()}>
          <CloseIcon className={classes.dialogCloseIcon} />
        </IconButton>
      </DialogTitle>
      <Formik initialValues={{ persons: persons, add: [], remove: [] }} onSubmit={handleComplete}>
        {({ submitForm, isSubmitting, setFieldValue, values }) => (
          <>
            <DialogContent classes={{ root: classes.content }}>
              {!props.hidePersonSelector && (
                <VolunteerSelector
                  label="Champions"
                  placeholder="Select Champions"
                  alternative
                  volunteers={values.persons}
                  onChange={(value: PersonRef[]) => setFieldValue('persons', value)}
                  existingVolunteers={[]}
                />
              )}
              <Field
                name="add"
                label="Add"
                placeholder={values.add?.length ? '' : 'Select tag(s) to add'}
                options={tags}
                addOption={(option: Tag) => {
                  setTags(prev => [...prev, option])
                }}
                notched
                component={TagAutocomplete}
                getOptionLabel={({ name }: Tag) => name}
                getOptionDisabled={(option: Tag) => values.remove?.some(({ id }) => id === option.id)}
                className={styles.gap}
                variant={'form'}
              />
              <Field
                name="remove"
                label="Remove"
                placeholder={values.remove?.length ? '' : 'Select tag(s) to remove'}
                options={tags}
                addOption={(option: Tag) => {
                  setTags(prev => [...prev, option])
                }}
                notched
                getOptionDisabled={(option: Tag) => values.add?.some(({ id }) => id === option.id)}
                component={TagAutocomplete}
                getOptionLabel={({ name }: Tag) => name}
                className={styles.gap}
                variant={'form'}
              />
            </DialogContent>
            <DialogActions className={classes.actions}>
              <OutlinedButton onClick={close}>Cancel</OutlinedButton>
              <ContainedButton
                disabled={
                  values.persons.length === 0 || (values.add.length === 0 && values.remove.length === 0) || isSubmitting
                }
                onClick={submitForm}
              >
                Save
              </ContainedButton>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  )
}

export default (props: AddRemoveTagsPromptProps) => (
  <ThemeProvider theme={muiTheme}>
    <AddRemoveTagsPrompt {...props} />
  </ThemeProvider>
)
