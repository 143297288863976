import { useCallback } from 'react'
import { useFetch } from 'civic-champs-shared/api/hooks'
import { isUndefined } from 'lodash'

const useUpdateNonSchedulableOpportunity = useDefaultConfig => {
  const [request, status] = useFetch()
  const postOpportunity = useCallback(
    (body, encodedOccurrence) => {
      const queryString = isUndefined(useDefaultConfig)
        ? ''
        : '?useDefaultConfig=' + (useDefaultConfig ? 'true' : 'false')
      return request({
        method: 'put',
        url: `/opportunity_instances/${encodedOccurrence}/non_schedulable${queryString}`,
        config: { body },
      })
    },
    [request, useDefaultConfig],
  )

  return [postOpportunity, status]
}

export default useUpdateNonSchedulableOpportunity
