import { useDateTimeUtils } from 'civic-champs-shared/utils/useDateTimeUtils'
import { Opportunity, OpportunityVisibility } from '../interfaces'
import { OpportunityResponseWithRecurrenceInfo } from '../interfaces/interfaceCreateEditEvent'
import { EventQuestionSet } from './useGetEventQuestionSets'
import { pick } from 'lodash'
import { FormData, getInitialEditMode } from '../helpers/add-edit-helpers'
import { useCallback } from 'react'
import { DEFAULT_GEOFENCING } from '../../civic-champs-shared/constants/GEO_DATA'
import { RECURRING_TYPE } from '../../civic-champs-shared/core/utils/constants/RECURRING_TYPE_OPTIONS'
import { useFeatureEnabled } from '../../core/feature/hooks'

const getDefaultFormData = ({ useTemplate = false }: { useTemplate?: boolean } = {}) => {
  const startTime = new Date()
  const endTime = new Date()
  startTime.setHours(12, 0, 0)
  endTime.setHours(13, 0, 0)

  return {
    name: '',
    description: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    visibility: OpportunityVisibility.PUBLIC,
    groups: [],
    startDate: startTime,
    endDate: null,
    startTime,
    endTime,
    geofencing: DEFAULT_GEOFENCING,
    isTest: false,
    locationDetails: '',
    locationIsAddress: true,
    opportunityTemplate: null,
    isSchedulable: true,
    recurringType: RECURRING_TYPE.NEVER,
    roles: [{ roleId: null, quantity: null, registrations: 0 }],
    contactEmail: '',
    contactName: '',
    contactPhoneNumber: '',
    instructions: '',
    questionnaires: [],
    waivers: [],
    organizationLocationId: null,
    useTemplate,
  } as FormData
}

export const useGetFormDataFromEvent = ({ useTemplate = false }: { useTemplate?: boolean } = {}) => {
  const { fakeAsLocalTimeForUI } = useDateTimeUtils()
  const isCalendarEventSwitchEnabled = useFeatureEnabled('NewAddEventUICalendarEvent')
  return useCallback(
    ({
      event,
      questionSets,
      isQuestionnaireAsSurveyEnabled,
    }: {
      event?: Opportunity | OpportunityResponseWithRecurrenceInfo
      questionSets: EventQuestionSet[]
      isQuestionnaireAsSurveyEnabled: boolean
    }): FormData => {
      const defaultFormData = getDefaultFormData({ useTemplate })
      const eventFormData = pick(event || {}, Object.keys(defaultFormData))
      const dates = event
        ? {
            startDate: fakeAsLocalTimeForUI(event.startsAt).toDate(),
            startTime: fakeAsLocalTimeForUI(event.startsAt).toDate(),
            endDate: event.endsAt ? fakeAsLocalTimeForUI(event.endsAt).toDate() : undefined,
            endTime: event.endsAt ? fakeAsLocalTimeForUI(event.endsAt).toDate() : undefined,
          }
        : {}
      let schedulableFormData: Partial<FormData> = {
        isSchedulable: isCalendarEventSwitchEnabled,
      }
      if (event && (event as OpportunityResponseWithRecurrenceInfo)?.schedulable) {
        const schedulableEvent = event as OpportunityResponseWithRecurrenceInfo
        schedulableFormData = {
          recurringType: schedulableEvent.recurrenceInfo.type,
          roles: schedulableEvent.timeshifts?.flatMap(({ roles }) =>
            roles.map(({ id, available, all }) => ({ roleId: id, registrations: all - available, quantity: all })),
          ),
          timeShiftId: schedulableEvent.timeshifts?.[0].id,
          timeShiftName: schedulableEvent.timeshifts?.[0].name,
          contactEmail: schedulableEvent.contact_email,
          contactName: schedulableEvent.contact_name,
          contactPhoneNumber: schedulableEvent.contact_phone_number,
          instructions: schedulableEvent.instructions,
          questionnaires: questionSets.map((eventQuestionSet: any) => ({
            id: isQuestionnaireAsSurveyEnabled ? eventQuestionSet.survey?.id : eventQuestionSet.questionSet.id,
            name: eventQuestionSet.questionSet.name,
          })),
          waivers: schedulableEvent.requiredCredentials?.map(({ id, title }) => ({
            id,
            name: title,
          })),
          isSchedulable: true,
          editMode: getInitialEditMode(schedulableEvent),
          useTemplate: !!schedulableEvent.locationId,
          pointOfContactPersons: schedulableEvent.pointOfContactPersons,
        }
      }
      return {
        ...defaultFormData,
        ...eventFormData,
        ...schedulableFormData,
        ...dates,
        locationIsAddress: !event?.locationDetails,
        organizationLocationId: (event as OpportunityResponseWithRecurrenceInfo)?.locationId || null,
      } as FormData
    },
    [fakeAsLocalTimeForUI, isCalendarEventSwitchEnabled, useTemplate],
  )
}
