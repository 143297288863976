import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'
import AddEditOpportunityTemplatePrompt from 'Event/components/AddEditOpportunityTemplatePrompt'
import { useCallback } from 'react'
import {
  OpportunityTemplate,
  OpportunityTemplatePayload,
  OpportunityTemplatePersonGroupPayload,
} from 'volunteering/opportunities/interfaces'
import useUpdateOpportunityTemplate from 'volunteering/opportunities/hooks/useUpdateOpportunityTemplate'
import useCreateOpportunityTemplate from 'volunteering/opportunities/hooks/useCreateOpportunityTemplate'
import { useCreateApiNotification } from 'civic-champs-shared/api/hooks'
import { FormReferenceItem } from 'Event/helpers/add-edit-helpers'
import { EventGroup } from 'Event/interfaces/interfaceCreateEditEvent'

export const useShowTemplateAddEditPrompt = ({
  onCreate,
  onUpdate,
}: {
  onCreate?: (template: OpportunityTemplate) => void
  onUpdate?: (template: OpportunityTemplate) => void
} = {}) => {
  const showAddEditOpportunityTemplatePrompt = useShowPrompt(AddEditOpportunityTemplatePrompt)
  const createNotification = useCreateApiNotification()
  const [updateOpportunityTemplate] = useUpdateOpportunityTemplate()
  const [createOpportunityTemplate] = useCreateOpportunityTemplate()
  const update = useCallback(
    async (
      id: number,
      {
        body,
        groups,
        credentials,
        surveys,
      }: {
        body: OpportunityTemplatePayload
        groups: Omit<OpportunityTemplatePersonGroupPayload, 'opportunityTemplateId'>[]
        credentials: number[]
        surveys: number[]
      },
    ) => {
      const notification = createNotification('Updating template')

      try {
        const template = await updateOpportunityTemplate(id, {
          ...body,
          groups,
          credentialIds: credentials,
          surveyIds: surveys,
        })
        onUpdate?.(template)
        notification.onSuccess('Template updated')
      } catch (e) {
        notification.onError('Failed to update template', e)
      }
    },
    [createNotification, onUpdate, updateOpportunityTemplate],
  )

  const create = useCallback(
    async ({
      body,
      groups,
      credentials,
      surveys,
    }: {
      body: OpportunityTemplatePayload
      groups: Omit<OpportunityTemplatePersonGroupPayload, 'opportunityTemplateId'>[]
      credentials: number[]
      surveys: number[]
    }) => {
      const notification = createNotification('Creating template')
      let template: OpportunityTemplate | undefined
      try {
        template = await createOpportunityTemplate({
          ...body,
          groups,
          credentialIds: credentials,
          surveyIds: surveys,
        })
        onCreate?.(template)
        notification.onSuccess('Template created')
      } catch (e) {
        notification.onError('Failed to create template', e)
      }
    },
    [createNotification, createOpportunityTemplate, onCreate],
  )

  return useCallback(
    async (
      opportunityTemplate?: OpportunityTemplate | Omit<OpportunityTemplate, 'id'>,
      additionalData?: {
        questionnaires: FormReferenceItem[]
        waivers: FormReferenceItem[]
        groups: EventGroup[]
      },
    ) => {
      const {
        values,
        groups,
        questionnaires: surveys,
        waivers: credentials,
      } = (await showAddEditOpportunityTemplatePrompt({
        opportunityTemplate,
        additionalData,
      })) as {
        values: OpportunityTemplatePayload
        groups: Omit<OpportunityTemplatePersonGroupPayload, 'opportunityTemplateId'>[]
        waivers: number[]
        questionnaires: number[]
      }
      if (opportunityTemplate && 'id' in opportunityTemplate) {
        update(opportunityTemplate.id, { body: values, groups, credentials, surveys })
      } else {
        create({ body: values, groups, credentials, surveys })
      }
    },
    [create, showAddEditOpportunityTemplatePrompt, update],
  )
}
