import { isNil, omitBy } from 'lodash'
import { useCallback } from 'react'
import useFetchRefactored from 'civic-champs-shared/api/hooks/useFetchRefactored'
import { useCurrentOrg } from 'auth/hooks'
import { Role, RoleWithReporting } from '../types'

export const useFetchRoles = () => {
  const organization = useCurrentOrg()
  const [request, { loading, error }] = useFetchRefactored<(Role | RoleWithReporting)[]>()
  const fetchRoles = useCallback(
    async (params: { reporting?: boolean; defaultOnly?: boolean; forLocations?: boolean }) =>
      request({
        url: `/organizations/${organization.id}/roles`,
        config: {
          queryStringParameters: omitBy(params, isNil),
        },
      }),
    [request, organization.id],
  )

  return { fetchRoles, loading, error }
}
