import { useCallback } from 'react'
import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'
import { useCreateApiNotification } from 'civic-champs-shared/api/hooks'
import { AddressType, OrganizationLocation, OrganizationLocationReporting } from '../types'
import { useSaveLocation } from 'locations/hooks/useSaveLocation'
import AddEditLocationModal from 'locations/components/AddEditLocationModal'
import { Action } from 'civic-champs-shared/api/hooks/useRemoteCollection'
import { OpportunityPayload, OpportunityVisibility } from '../../Event/interfaces'
import useCreateNonSchedulableOpportunity from '../../volunteering/opportunities/hooks/useCreateNonSchedulableOpportunity'
import useUpdateNonSchedulableOpportunity from '../../volunteering/opportunities/hooks/useUpdateNonSchedulableOpportunity'
import { useCurrentOrg } from '../../auth/hooks'
import moment from 'moment-timezone'
import API_ENDPOINTS from '../../Event/constants/API_ENDPOINTS'
import BaseService from '../../Event/services/base.service'
import {
  AssociationType,
  OpportunityOccurrencePersonGroup,
  OpportunityResponse,
} from '../../Event/interfaces/interfaceCreateEditEvent'
import useEndOpportunityFromLocations from 'locations/hooks/useCancelEventFromLocation'
import { useGetLocationOrder } from 'locations/hooks/useGetLocationOrder'
import { useFeatureEnabled } from 'core/feature/hooks'
import { useFetchOpportunityLocationByOpportunityOccurrence } from 'locations/hooks/useFetchOpportunityLocationByOpportunityOccurrence'
import { mapToEventGroup } from '../../Event/helpers/add-edit-helpers'

export const useAddEditLocationPrompt = ({
  eagerAdd,
  replace,
  eager = true,
}: {
  replace?: Action<OrganizationLocationReporting>
  eagerAdd?: Action<OrganizationLocationReporting>
  eager?: boolean
} = {}) => {
  const createNotification = useCreateApiNotification()
  const showPrompt = useShowPrompt(AddEditLocationModal)
  const offersEnabled = useFeatureEnabled('StandaloneOffers')
  const endOpportunityFromLocation = useEndOpportunityFromLocations(replace, false, eager)
  const getLocationOrder = useGetLocationOrder()
  const { fetchLocation: fetchLocationByOpportuntyOccurence } = useFetchOpportunityLocationByOpportunityOccurrence()
  const { saveLocation } = useSaveLocation()
  const org = useCurrentOrg()
  const [createOpportunity] = useCreateNonSchedulableOpportunity(org.id) as [
    (opportunity: OpportunityPayload) => Promise<any>,
  ]
  const [updateOpportunity] = useUpdateNonSchedulableOpportunity() as [
    (opportunity: OpportunityPayload, encodedOccurrence: string) => Promise<any>,
  ]
  return useCallback(
    async (
      location?: OrganizationLocation | OrganizationLocationReporting,
      forceEnableCheckIns?: true,
    ): Promise<OpportunityResponse | undefined> => {
      if (location?.encodedOccurrence) {
        const base = new BaseService()
        const eventGroups = await base.getJSON(API_ENDPOINTS.Events.getOccurrenceGroups(location.encodedOccurrence))
        if (location.type === AddressType.Public) {
          location.onboardingGroups = eventGroups
            .filter(
              (group: OpportunityOccurrencePersonGroup) =>
                group.associationType === AssociationType.ADD_PARTICIPANTS_TO_GROUP,
            )
            .map(mapToEventGroup)
        } else {
          location.visibilityGroups = eventGroups
            .filter(
              (group: OpportunityOccurrencePersonGroup) =>
                group.associationType === AssociationType.EVENT_PRIVATE_TO_MEMBERS,
            )
            .map(mapToEventGroup)
        }
      }
      const formData = await showPrompt({ location, forceEnableCheckIns })
      const notification = createNotification('Saving location')
      try {
        let savedLocation
        let response
        if (formData.type === AddressType.Event) {
          if (location?.encodedOccurrence) {
            await endOpportunityFromLocation({ ...location, name: formData.name } as OrganizationLocationReporting)
          }
          savedLocation = await saveLocation(formData)
          let order = savedLocation.order
          if (offersEnabled) {
            order = (await getLocationOrder({ locationId: savedLocation.id })).order
          }
          if (!location?.encodedOccurrence) {
            if (formData.id) {
              replace?.({ ...savedLocation, order, type: AddressType.Event })
            } else {
              eagerAdd?.({ ...savedLocation, order, type: AddressType.Event })
            }
          }
        } else {
          const event = {
            name: formData.name,
            description: formData.description,
            visibility:
              formData.type === AddressType.Public
                ? OpportunityVisibility.PUBLIC
                : OpportunityVisibility.SELECT_GROUPS_ONLY,
            isTest: false,
            isSchedulable: false,
            address: formData.line1,
            city: formData.city,
            state: formData.state,
            zip: formData.zip,
            locationDetails: formData.locationDetails,
            startsAt: formData.startsAt ? formData.startsAt : moment.tz(org.timeZone).startOf('day').toDate(),
            endsAt: formData.endsAt || null,
            geofencing: formData.geofencing,
            groups: formData.type === AddressType.Public ? formData.onboardingGroups : formData.visibilityGroups,
            locationIsAddress: formData.locationIsAddress,
            recordLocationName: true,
            roleIds: formData.roleIds,
            locationId: location?.id,
          } as OpportunityPayload
          if (location?.encodedOccurrence) {
            response = await updateOpportunity(event, location.encodedOccurrence)
          } else {
            response = await createOpportunity(event)
          }

          const { order } = await getLocationOrder({ opportunityOccurrenceId: response.id })

          const newLocation = {
            ...(await fetchLocationByOpportuntyOccurence(response.id)),
            order,
            timesUsedActive: 1,
            type: formData.type,
          }

          if (formData.id) {
            replace?.(newLocation)
          } else {
            eagerAdd?.(newLocation)
          }
        }
        notification.onSuccess('Location was saved')

        return response
      } catch (error) {
        notification.onError('Failed to save location', error)
      }
    },
    [
      showPrompt,
      createNotification,
      saveLocation,
      offersEnabled,
      endOpportunityFromLocation,
      getLocationOrder,
      replace,
      eagerAdd,
      org.timeZone,
      fetchLocationByOpportuntyOccurence,
      updateOpportunity,
      createOpportunity,
    ],
  )
}

export default useAddEditLocationPrompt
