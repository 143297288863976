import cn from 'classnames'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button, { ButtonProps } from '@material-ui/core/Button/Button'
import './ContainedButton.scss'

const useStyles = makeStyles({
  button: {
    backgroundColor: '#FFF',
    color: '#0F5DB5',
    border: '1px #EEF3F6 solid',
    textTransform: 'none',
    fontFamily: 'Nunito, sans-serif',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.5px',
    borderRadius: '100px',
    padding: '8px',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: '#EEF3F6',
    },
  },
  icon: {
    color: '#0F5DB5',
    margin: 0,
  },
})

export const ContainedSmallButton: React.FC<Omit<ButtonProps, 'variant' | 'color'>> = ({
  className,
  disabled,
  ...props
}) => {
  const classes = useStyles()
  return (
    <Button
      {...props}
      variant="contained"
      color="secondary"
      disabled={disabled}
      className={cn(classes.button, 'contained-button', className)}
      classes={{
        ...props.classes,
        startIcon: classes.icon,
        endIcon: classes.icon,
      }}
    />
  )
}

export default ContainedSmallButton
