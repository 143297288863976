import React, { useEffect, useRef, useState } from 'react'
import { EventExpansionPanelSummary } from 'Event/components/EventExpansionPanelSummary'
import { EventExpansionPanelDetails } from 'Event/components/EventExpansionPanelDetails'
import { EventExpansionPanel } from 'Event/components/EventExpansionPanel'
import { useExpansionPanelSummaryStyle } from 'Event/hooks/useExpansionPanelSummaryStyle'
import { ActivityLog } from 'tracking/activity/components/ActivityLog'
import { OrganizationLocationReporting } from 'locations/types'
import { useGetOpportunityInstance } from 'Event/hooks/useGetOpportunityInstance'

export const LocationDrilldownActivities = ({
  location,
  scrollTop,
}: {
  location: OrganizationLocationReporting
  scrollTop: number
}) => {
  const [expanded, setExpanded] = useState(true)
  const summaryRef = useRef<HTMLDivElement>(null)
  const detailsRef = useRef<HTMLDivElement>(null)
  const [getOpportunity, { result: opportunity }] = useGetOpportunityInstance()
  useEffect(() => {
    if (location.encodedOccurrence) {
      getOpportunity(location.encodedOccurrence)
    }
  }, [getOpportunity, location.encodedOccurrence])

  const style = useExpansionPanelSummaryStyle({
    expanded,
    scrollTop,
    summaryRef,
    detailsRef,
  })

  return (
    <>
      <EventExpansionPanel onChange={(_, expanded) => setExpanded(expanded)}>
        <EventExpansionPanelSummary name="location-activities" style={style} ref={summaryRef}>
          <h1>Activity Log</h1>
        </EventExpansionPanelSummary>
        <EventExpansionPanelDetails ref={detailsRef}>
          <ActivityLog
            locationId={location.id}
            tableName="locationActivityLog"
            opportunity={opportunity || undefined}
          />
        </EventExpansionPanelDetails>
      </EventExpansionPanel>
    </>
  )
}
