import React from 'react'
import get from 'lodash/get'
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined'
import { makeStyles } from '@material-ui/core/styles'

import useSuccessNotification from 'civic-champs-shared/api/hooks/useSuccessNotification'

import {
  OpportunityOccurrencePersonGroup,
  OpportunityResponseWithRecurrenceInfo,
  OpportunityStatus,
} from 'Event/interfaces/interfaceCreateEditEvent'
import { getVisibilityLabel, OpportunityVisibility as OpportunityVisibilityType } from 'Event/interfaces'
import { encodeOccurrenceFromEvent } from 'Event/helpers/encodeOccurrence'
import { useOpportunityPrivateTokens } from 'Event/scenes/events/detail/private-token/hooks'
import { ExpandableCell } from 'core/table/cells/modern'

const useStyles = makeStyles({
  link: {
    color: '#0F5DB5',
  },
  copy: { marginLeft: '8px', cursor: 'pointer', color: '#0F5DB5', width: '20px', marginBottom: '-8px' },
})

export const EventVisibility = ({
  event,
  visibilityGroups,
}: {
  event: OpportunityResponseWithRecurrenceInfo
  visibilityGroups?: OpportunityOccurrencePersonGroup[]
}) => {
  let label = getVisibilityLabel(event.visibility)
  if (event.visibility === OpportunityVisibilityType.SELECT_GROUPS_ONLY) {
    label += ':'
  }
  const encodedOccurrence = encodeOccurrenceFromEvent(event)
  const classes = useStyles()
  const { oneToken, loading } = useOpportunityPrivateTokens(encodedOccurrence)
  const success = useSuccessNotification(true)
  const isPrivate = event.visibility === OpportunityVisibilityType.PRIVATE && oneToken
  let root = process.env.REACT_APP_EVENTS_SITE_ENDPOINT
  let orgId = get(event, 'organization.id')
  let baseUrl = `${root}/organizations/${orgId}/opportunities/${encodedOccurrence}`

  if (isPrivate) {
    baseUrl += '?token=' + oneToken
  }

  return (
    <>
      {label}
      {visibilityGroups && (
        <>
          <br />
          <ExpandableCell
            value={visibilityGroups
              .map(({ group: { name, closed }, approvedMembersOnly }) => {
                if (!closed) return name
                return `${name} - ${approvedMembersOnly ? 'Approved Members' : 'Applicants'}`
              })
              .join(', ')}
            noPadding
          />
        </>
      )}
      {!loading && event.schedulable && event.status !== OpportunityStatus.Concluded && (
        <>
          <br />
          <a target="_blank" rel="noopener noreferrer" className={classes.link} href={baseUrl}>
            {isPrivate ? 'Private Registration Link' : 'Open Registration Link'}
          </a>
          <FileCopyOutlinedIcon
            className={classes.copy}
            onClick={async () => {
              await navigator.clipboard.writeText(baseUrl)
              success('Link copied')
            }}
          />
        </>
      )}
    </>
  )
}
