import React, { useCallback, useEffect } from 'react'
import { StatusCodes } from 'http-status-codes'

import MultiStepBar from '../components/multi-step-bar'
import BaseInfoModule from '../modules/events/create-edit/basic-info/index'
import ParticipantsModule from '../modules/events/create-edit/participants'
import LocationModule from '../modules/events/create-edit/location/index'
import ShiftModule from '../modules/events/create-edit/shifts/index'
import CustomQuestionModule from '../modules/events/create-edit/custom-questions/index'
import ReviewModule from '../modules/events/create-edit/review/index'
import SummaryInfo from '../modules/events/create-edit/summary-info/index'
import classNames from 'classnames'
import API_ENDPOINTS from '../constants/API_ENDPOINTS'
import PageNotFound from '../scenes/page-not-found'
import Loader from '../components/loader'
import BaseService from '../services/base.service'
import CongratulationsPopup from '../modules/events/create-edit/congratulations-popup'
import ErrorPopup from '../modules/events/create-edit/error-popup'
import { EVENT_FORM_STEPS, FORM_STEPS } from '../constants/EVENT_FORM_STEPS'
import { encodeOccurrenceFromEvent } from '../helpers/encodeOccurrence'

import './edit-event/index.scss'
import { OpportunityOccurrenceQuestionSet } from 'civic-champs-shared/question-sets/types'
import {
  AssociationType,
  EditEventFields,
  OpportunityOccurrencePersonGroup,
} from '../interfaces/interfaceCreateEditEvent'
import { mapEventPayload } from './events/helpers/mapEventPayload'
import { mapEventGeofencingToGeofencing } from 'utils/event'
import isNull from 'lodash/isNull'
import moment from 'moment'
import { useCurrentOrg } from '../../civic-champs-shared/auth/hooks'
import { useShowPrompt } from '../../civic-champs-shared/core/modal/hooks'
import EditRecurringEventConfirmDialog from '../components/EditRecurringEventConfirmDialog'
import { getInitialEditMode, mapToEventGroup } from '../helpers/add-edit-helpers'

const base = new BaseService()

export const EditEventScene = (props: any) => {
  const [isSendingDraftRequest, setIsSendingDraftRequest] = React.useState(false)
  const [isSendingPublicRequest, setIsSendingPublicRequest] = React.useState(false)
  const [isLoadingEvent, setIsLoadingEvent] = React.useState(false)
  const [isShowPopupCongrats, setIsShowPopupCongrats] = React.useState(false)
  const [orgId, setOrgId] = React.useState(1)
  const [encodedOccurrence, setEncodedOccurrence] = React.useState<null | string>(null)
  const [isShowPopupError, setIsShowPopupError] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState('')
  const [clearOnExit, setClearOnExit] = React.useState(true)

  const { currentStep, event } = props.editEventForm

  const currentOrganization = useCurrentOrg()

  const showEditRecurringPrompt = useShowPrompt(EditRecurringEventConfirmDialog)

  const getEvent = useCallback(async () => {
    setIsLoadingEvent(true)
    try {
      const { encodedOccurrence } = props.match.params
      const { isQuestionnaireAsSurveyEnabled } = props
      const event = await base.getJSON(
        API_ENDPOINTS.Events.getOccurrence({ encodedOccurrence, populateRecurrenceInfo: true }),
      )

      const eventQuestionSets = await base.getJSON(
        API_ENDPOINTS.Events.getOccurrenceQuestionSets(encodedOccurrence, isQuestionnaireAsSurveyEnabled),
      )

      const eventGroups = await base.getJSON(API_ENDPOINTS.Events.getOccurrenceGroups(encodedOccurrence))
      if (
        !event.error &&
        event.statusCode !== 500 &&
        !eventQuestionSets.error &&
        eventQuestionSets.statusCode !== 500 &&
        !eventGroups.error &&
        eventGroups.statusCode !== 500
      ) {
        setIsLoadingEvent(false)
        return {
          ...event,
          geofencing: mapEventGeofencingToGeofencing(event.geofencing),
          questionSets: eventQuestionSets.map((eventQuestionSet: OpportunityOccurrenceQuestionSet) => ({
            ...(isQuestionnaireAsSurveyEnabled
              ? {
                  surveyId: eventQuestionSet.survey?.id,
                }
              : {
                  questionSetId: eventQuestionSet.questionSet.id,
                }),
            name: eventQuestionSet.questionSet.name,
            required: eventQuestionSet.required,
          })),
          visibilityGroups: eventGroups
            .filter(
              (group: OpportunityOccurrencePersonGroup) =>
                group.associationType === AssociationType.EVENT_PRIVATE_TO_MEMBERS,
            )
            .map(mapToEventGroup),
          onboardingGroups: eventGroups
            .filter(
              (group: OpportunityOccurrencePersonGroup) =>
                group.associationType === AssociationType.ADD_PARTICIPANTS_TO_GROUP,
            )
            .map(mapToEventGroup),
        }
      }
      setIsLoadingEvent(false)
    } catch (e) {
      console.error(e)
    }
  }, [props])

  const checkPath = useCallback(() => {
    const locationPath = props.history.location.pathname.split('/')
    const locationPathLength = locationPath.length
    const currentPath = locationPath[locationPathLength - 1]
    return EVENT_FORM_STEPS.some(item => item.path === currentPath)
  }, [props.history.location.pathname])

  const pushCurrentPath = useCallback(
    (currentPath: string) => {
      props.history.push(`/events/${props.match.params.encodedOccurrence}/edit-event/${currentPath}`)
    },
    [props.history, props.match.params.encodedOccurrence],
  )

  const getEventListing = useCallback(
    () => props.history.push(`/events/${encodedOccurrence}/${orgId}`),
    [encodedOccurrence, orgId, props.history],
  )

  const handleCloseErrorModal = useCallback(() => {
    setIsShowPopupError(false)
    setErrorMessage('')
  }, [])

  const handleCloseCongratsModal = useCallback(() => {
    setIsShowPopupCongrats(false)
    getEventListing()
  }, [getEventListing])

  const handleSaveDraftFn = useCallback(async () => {
    setIsSendingDraftRequest(true)
    try {
      const { event } = props.editEventForm
      const endpoint = API_ENDPOINTS.Events.occurrenceRefactorUpdateEvent(props.match.params.encodedOccurrence, 'draft')
      const response = await base.putJSON(endpoint, mapEventPayload(event, currentOrganization.timeZone))

      if (!response.error && response.statusCode !== 500) {
        setIsSendingDraftRequest(false)
        setEncodedOccurrence(encodeOccurrenceFromEvent(response))
        getEventListing()
      }
      setErrorMessage(response.message)
      setIsShowPopupError(true)
      setIsSendingDraftRequest(false)
    } catch (e) {
      console.error(e)
    }
  }, [getEventListing, currentOrganization.timeZone, props.editEventForm, props.match.params.encodedOccurrence])

  const nextStep = useCallback(() => {
    const { currentStep } = props.editEventForm
    if (currentStep === EVENT_FORM_STEPS.length) return
    const currentPath = EVENT_FORM_STEPS.filter(item => item.step === currentStep + 1)[0].path
    pushCurrentPath(currentPath)
    props.changeStep(currentStep + 1)
  }, [props, pushCurrentPath])

  const prevStep = useCallback(() => {
    const { currentStep } = props.editEventForm
    if (currentStep === 1) return
    const currentPath = EVENT_FORM_STEPS.filter(item => item.step === currentStep - 1)[0].path
    pushCurrentPath(currentPath)
    props.changeStep(currentStep - 1)
  }, [props, pushCurrentPath])

  const handleUpdatePublish = useCallback(
    async (changes: Partial<EditEventFields> = {}) => {
      setIsSendingPublicRequest(true)
      try {
        const endpoint = API_ENDPOINTS.Events.occurrenceRefactorUpdateEvent(
          props.match.params.encodedOccurrence,
          'publish',
        )
        const response = await base.putJSON(
          endpoint,
          mapEventPayload({ ...event, ...changes }, currentOrganization.timeZone),
        )

        if (!response.error && response.statusCode !== StatusCodes.INTERNAL_SERVER_ERROR) {
          setIsSendingPublicRequest(false)
          setEncodedOccurrence(encodeOccurrenceFromEvent(response))
          event.published ? getEventListing() : setIsShowPopupCongrats(true)
        }

        if (
          [StatusCodes.BAD_REQUEST, StatusCodes.CONFLICT, StatusCodes.INTERNAL_SERVER_ERROR].includes(
            response.statusCode,
          )
        ) {
          setIsSendingPublicRequest(false)
          setErrorMessage(
            response.statusCode === StatusCodes.INTERNAL_SERVER_ERROR
              ? 'Unknown error happened, please contact your system administrator'
              : response.message,
          )
          setIsShowPopupError(true)
        }

        setIsSendingPublicRequest(false)
      } catch (e) {
        console.error(e)
      }
    },
    [currentOrganization.timeZone, event, getEventListing, props.match.params.encodedOccurrence],
  )

  const handleOpenEditModal = useCallback(async () => {
    const { editType } = await showEditRecurringPrompt()
    const changes = { editMode: editType, is_recurring: editType !== 'single' }
    props.setNewData(changes)
    if (event.published) {
      return handleUpdatePublish(changes)
    } else {
      return handleSaveDraftFn()
    }
  }, [event.published, handleSaveDraftFn, handleUpdatePublish, props, showEditRecurringPrompt])

  const handleSaveDraft = useCallback(event.is_recurring ? handleOpenEditModal : handleSaveDraftFn, [
    event.is_recurring,
    handleOpenEditModal,
    handleSaveDraftFn,
  ])

  useEffect(() => {
    ;(async () => {
      const response = await getEvent()
      // We do not want to rewrite event data if we came back from adding new question set
      // and maintained all event data in redux store
      if (
        isNull(response) ||
        (!isNull(response) &&
          (response?.occurrenceId !== props.editEventForm.event?.occurrenceId ||
            response?.opportunityId !== props.editEventForm.event?.opportunityId))
      ) {
        props.setEventDataFromApi({
          ...response,
          editMode: getInitialEditMode(response),
          // keeping only date part to avoid timezone-difference related date shifts
          startsAt: moment.tz(response.startsAt, response.organization.timeZone).format('YYYY-MM-DD'),
        })
      }
      if (response != null) {
        setOrgId(response.organization.id)
      }

      if (checkPath()) {
        const { currentStep } = props.editEventForm
        const currentPath = EVENT_FORM_STEPS.filter(item => item.step === currentStep)[0].path
        pushCurrentPath(currentPath)
        props.changeStep(currentStep)
      }

      if (props.match.params.encodedOccurrence) {
        setEncodedOccurrence(props.match.params.encodedOccurrence)
      }
    })()

    return () => {
      if (clearOnExit) {
        props.changeStep(1)
        props.clearEventForm()
      }
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  if (!checkPath()) {
    return <PageNotFound />
  }

  if (isLoadingEvent) {
    return <Loader />
  }

  const renderSteps: { [key in FORM_STEPS]: React.ReactNode } = {
    [FORM_STEPS.BASIC]: (
      <BaseInfoModule
        onNextClick={nextStep}
        onSaveDraftClick={handleSaveDraft}
        isSendingDraftRequest={isSendingDraftRequest}
        event={event}
        encodedOccurrence={encodedOccurrence}
        editMode
        {...props}
      />
    ),
    [FORM_STEPS.PARTICIPANTS]: (
      <ParticipantsModule
        onPrevClick={prevStep}
        onNextClick={nextStep}
        onSaveDraftClick={handleSaveDraft}
        isSendingDraftRequest={isSendingDraftRequest}
        event={event}
        {...props}
      />
    ),
    [FORM_STEPS.LOCATION]: (
      <LocationModule
        onPrevClick={prevStep}
        onNextClick={nextStep}
        onSaveDraftClick={handleSaveDraft}
        isSendingDraftRequest={isSendingDraftRequest}
        event={event}
        editMode
        {...props}
      />
    ),
    [FORM_STEPS.SHIFTS]: (
      <ShiftModule
        onPrevClick={prevStep}
        onNextClick={nextStep}
        onSaveDraftClick={handleSaveDraft}
        isSendingDraftRequest={isSendingDraftRequest}
        event={event}
        editMode
        {...props}
      />
    ),
    [FORM_STEPS.QUESTIONS]: (
      <CustomQuestionModule
        onPrevClick={prevStep}
        onNextClick={nextStep}
        onSaveDraftClick={handleSaveDraft}
        isSendingDraftRequest={isSendingDraftRequest}
        event={event}
        onBeforeAddEdit={() => setClearOnExit(false)}
        {...props}
      />
    ),
    [FORM_STEPS.REVIEW]: (
      <ReviewModule
        onPrevClick={prevStep}
        onSaveClick={event.is_recurring && event.published ? handleOpenEditModal : () => handleUpdatePublish()}
        onCancel={getEventListing}
        onSaveDraftClick={handleSaveDraft}
        isSendingDraftRequest={isSendingDraftRequest}
        isSendingPublicRequest={event.is_recurring ? false : isSendingPublicRequest}
        event={event}
        editMode
        {...props}
      />
    ),
  }

  const contentMainClasses = classNames('create-event-module-content__main', {
    'full-width': currentStep === FORM_STEPS.QUESTIONS || currentStep === FORM_STEPS.REVIEW,
  })

  return (
    <div className="main-container">
      <div className="create-event-module">
        <div className="buton-back-container">
          <div
            className="detail_event_block_back_arrow"
            onClick={() => props.history.push(`/events/${encodedOccurrence}/${orgId}`)}
          >
            <img src="/assets/icons/back-arrow.svg" alt="icon" className="arrow" />
            Event Details
          </div>
        </div>
        <span className="edit-event-text">Edit Event</span>
        <div className="create-event-module-steps">
          <MultiStepBar
            currentStep={currentStep}
            steps={EVENT_FORM_STEPS}
            changeStep={props.changeStep}
            editMode={true}
          />
        </div>
        <div className="create-event-module-content">
          <div className={contentMainClasses}>{renderSteps[currentStep as FORM_STEPS]}</div>
        </div>
        {isShowPopupCongrats && (
          <CongratulationsPopup
            editMode={true}
            isRecurring={event.is_recurring}
            handleClose={handleCloseCongratsModal}
          />
        )}
        {isShowPopupError && <ErrorPopup handleClose={handleCloseErrorModal} errorMessage={errorMessage} />}
      </div>
      {currentStep !== FORM_STEPS.REVIEW && (
        <div className="sidebar">
          <SummaryInfo editMode currentStep={currentStep} event={event} />
        </div>
      )}
    </div>
  )
}

export default EditEventScene
