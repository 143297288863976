import { makeStyles } from '@material-ui/core/styles'

export const useProfileInfoCardStyles = makeStyles(
  theme =>
    ({
      resendWrapper: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
      },
      resend: {
        color: '#006d41',
        borderColor: '#006d41',
        '& $resendIcon': {
          color: '#006d41',
        },
      },
      resendIcon: {},
      wrapper: {
        width: '427px',
        borderRadius: '5px',
        background: '#F8FAFF',
        boxShadow: '0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px rgba(0, 0, 0, 0.3)',
        display: 'flex',
        flexDirection: 'column',
        flexShrink: 0,
      },
      wrapperPadding: {
        paddingBottom: '20px',
        [theme.breakpoints.down(960)]: {
          paddingBottom: '0',
        },
      },
      header: {
        display: 'grid',
        gridTemplateColumns: '97px 1fr',
        padding: '20px 10px',
        gap: '10px',
        width: '100%',
        background: '#D6E3FF',
        borderRadius: '4px',
      },
      avatar: {
        width: '97px',
        height: '97px',
        borderRadius: '50px',
        placeSelf: 'center',
        backgroundColor: '#bdbdbd',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '1.25rem',
        fontFamily: 'Nunito, sans-serif',
        lineHeight: 1,
        color: 'white',
        backgroundSize: 'cover',
        position: 'relative',
        overflow: 'hidden',
        '&:hover $avatarEdit': {
          display: 'flex',
        },
      },
      avatarEdit: {
        display: 'none',
        width: '100%',
        height: '100%',
        position: 'absolute',
        cursor: 'pointer',
        left: 0,
        top: 0,
        justifyContent: 'space-around',
        alignItems: 'center',
        background: 'rgba(255, 255, 255, 0.6)',
        '&>svg': {
          color: '#0F5DB5',
          fontSize: '64px',
        },
      },
      info: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        '& > p': {
          fontFamily: 'Nunito, sans-serif',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '20px',
          textAlign: 'center',
          letterSpacing: '0.1px',
          color: '#000000',
          margin: 0,
        },
      },
      title: {
        // @ts-ignore
        fontWeight: '600 !important',
        fontSize: '24px !important',
      },
      padded: {
        padding: '0 20px',
        display: 'flex',
        flexDirection: 'column',
      },
      buttons: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        gap: '20px',
        marginTop: '10px',
        marginBottom: '15px',
      },
      table: {
        fontFamily: 'Nunito, sans-serif',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '20px',
        letterSpacing: '0.1px',
        color: '#000000',
        '& > tbody > tr > td': {
          paddingBottom: '10px',
          verticalAlign: 'top',
          '&:first-child': {
            width: '132px',
            fontWeight: 700,
          },
        },
      },
      spoiler: {
        width: '100%',
        height: '0px',
        border: '1px solid rgba(0, 0, 0, 0.1)',
        marginBottom: '10px',
      },
      subtitle: {
        fontFamily: 'Nunito, sans-serif',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '0.1px',
        color: '#000000',
        margin: 0,
        marginTop: '-3px',
        marginBottom: '4px',
      },
      subSubTitle: {
        fontFamily: 'Nunito, sans-serif',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '14px',
        lineHeight: '20px',
        letterSpacing: '0.1px',
        color: '#000000',
        margin: 0,
      },
      subContainer: {
        display: 'flex',
        justifyContent: 'space-between',
      },
      verified: {
        position: 'relative',
        left: '8px',
        top: '2px',
      },
      waivers: {
        display: 'flex',
        flexGrow: 0,
        width: '100%',
        gap: '5px',
        flexWrap: 'wrap',
        margin: '10px 0',
      },
      groups: {
        width: '100%',
        padding: '4px 0',
      },
      signedLabel: {
        fontFamily: 'Nunito, sans-serif',
        fontWeight: 700,
        fontSize: '12px',
        lineHeight: '16px',
        letterSpacing: '0.5px',
        color: '#0F5DB5',
      },
      invalidLabel: {
        color: '#BA1B1B',
      },
      deleteIcon: {
        width: '16px',
      },
      verifiedIcon: {
        color: '#0F5DB5',
        '&:hover': {
          color: '#0F5DB5',
        },
        '&$doNotContact': {
          color: '#BA1B1B',
          '&:hover': {
            color: '#BA1B1B',
          },
        },
      },
      chip: {
        background: 'white',
        maxWidth: '100%',
      },
      doNotContact: {
        color: '#BA1B1B',
      },
      outlinedSelectContainer: {
        display: 'flex',
        justifyContent: 'center',
      },
      outlinedSelect: {
        fontFamily: 'Nunito',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '16px',
        letterSpacing: '0.4px',
        borderRadius: '63px !important',
        border: '2px solid #0F5DB5',
        padding: '5px 10px 5px 15px',
        '& ~ fieldset': {
          border: 0,
        },
      },
    } as any),
)

export default useProfileInfoCardStyles
