import { makeStyles } from '@material-ui/core/styles'

export const useMultiGroupAddMembersPromptStyles = makeStyles({
  title: {
    cursor: 'move',
    padding: '20px 0 0',
    fontFamily: 'Poppins',
    fontWeight: 500,
    fontSize: '24px',
    lineHeight: '32px',
    color: '#000000',
    marginBottom: '20px',
  },
  content: {
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
    overflow: 'hidden',
    paddingTop: ({ hidePersonSelector }: { hidePersonSelector?: boolean }) => (hidePersonSelector ? '12px' : 0),
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px 0 0',
  },
  dialog: {
    background: '#F8FAFF',
    padding: '0 20px 20px',
    position: 'relative',
    maxWidth: '100%',
    width: '700px',
  },
  dialogCloseButton: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  dialogCloseIcon: {
    fontSize: '16px',
  },
  topFields: {
    display: 'flex',
    gap: '10px',
    '& > div': {
      width: '100%',
    },
  },
  whiteBg: {
    background: '#fff',
  },
})
export default useMultiGroupAddMembersPromptStyles
